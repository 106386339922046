import React, { memo, useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { Box, CircularProgress, IconButton, InputAdornment, TextField, ToggleButtonGroup, ToggleButton, Typography, FormControl, NativeSelect, Tooltip, Dialog, DialogTitle, DialogContent, Accordion, AccordionSummary, AccordionDetails, } from '@mui/material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import '@reactuiutils/horizontal-timeline/timeline.css';
import { Event as TimelineEvent, Subtitle, Timeline, Title, } from '@reactuiutils/horizontal-timeline';
import { PiShovelFill } from "react-icons/pi";
import { GiSaltShaker } from "react-icons/gi";
import { MdHistory } from "react-icons/md";
import { baseURL } from '../constants/routes.js';
import { useAuthContext } from './AuthProvider.js';
import { fetchPostalCodeCoords } from '../resources/fetchPostalCodeInfo.js';
import { METEO_DB_5500m_BOUND_FACTOR, MIN_SNOW_DEPTH_FOR_SALT_USAGE } from '../constants/weather.js';
import { handleTimeFormat } from '../constants/interpolationHelpers.js';
import DeviceDetect from './DeviceDetect.js';
import { CloseIcon, InfoDetailsIcon } from '../constants/formIcons.js';
import { validateLocation } from '../resources/validateSiteEligibility.js';
const LAST_EVENTS_MINIMUM_SALT_USAGE_IN_LBS = 5;
function InfoDetailsModal({ visibleModal, handleClose }) {
    let title = '';
    let content = '';
    if (visibleModal === 'carCount') {
        title = 'Driveway width';
        content = 'The width of your driveway in terms of the number of cars it can fit.\n' +
            'Calculations are based on the assumptions that driveways are 30 ft long and each car adds 11 ft of width.';
    }
    else if (visibleModal === 'houseOrientation') {
        title = 'House orientation';
        content = 'Whether your house is on the corner or side of the street. Corner houses typically have an extra 25 ft of frontage.';
    }
    else if (visibleModal === 'saltMeasurementUnit') {
        title = 'Salt measurement unit';
        content = 'Choose the most intuitive unit for measuring rock salt -- lbs, kg, or in cups. Rock salt usually comes in 50 lb bags.';
    }
    return (React.createElement(Dialog, { open: visibleModal !== null, onClose: handleClose, fullWidth: true, maxWidth: "sm", scroll: "paper" },
        React.createElement(DialogTitle, { sx: { position: 'relative', paddingRight: '48px' } },
            React.createElement(Typography, { variant: "h6" }, title),
            React.createElement(IconButton, { "aria-label": "close", onClick: handleClose, sx: {
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                } },
                React.createElement(CloseIcon, null))),
        React.createElement(DialogContent, { dividers: true },
            React.createElement(Box, { mb: 2 },
                React.createElement(Typography, { variant: "body1", paragraph: true }, content)))));
}
;
function DisclaimerModal() {
    return (React.createElement("div", null,
        React.createElement(Accordion, null,
            React.createElement(AccordionSummary, { expandIcon: React.createElement(ArrowDropDownIcon, null), "aria-controls": "panel2-content", id: "panel2-header" },
                React.createElement(Typography, { component: "span" }, "View service disclaimer")),
            React.createElement(AccordionDetails, null,
                React.createElement(Typography, null,
                    "Information contained on this page was obtained from meteorological data sources considered technically accurate and reliable.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "Since conditions of actual salt product use are beyond control of the supplier, it is assumed that users of this material have been fully trained according to the requirements of all applicable legislation and regulatory instruments.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "No warranty, expressed or implied, is made and Minuteman Technologies Inc. will not be liable for any losses, injuries or consequential damages which may result from the use of or reliance on any information contained on this page.")))));
}
;
export const TimelineChart = ({ events, postalCode, isValidPostalCode, startTimeUTC, endTimeUTC, saltMeasurementUnit, areaInSqFt, lengthUnit, }) => {
    function LoadingCircle({ postalCode }) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Box, { sx: {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '3rem',
                    backgroundColor: 'grey.100',
                    p: 2,
                } },
                React.createElement(CircularProgress, { color: "success", size: "2rem" }),
                React.createElement(Typography, { variant: 'h6', sx: { ml: 2 } },
                    "\uD83D\uDD0D Searching the ",
                    postalCode.toUpperCase(),
                    " area"))));
    }
    ;
    function convertSaltMeasurementUnit(areaInSqFt, saltMeasurementUnit, saltUsageInLbs) {
        if (saltMeasurementUnit === 'lbs') {
            return `${Math.round((areaInSqFt / 1000) * saltUsageInLbs)} lbs`;
        }
        else if (saltMeasurementUnit === 'kg') {
            return `${Math.round((areaInSqFt / 1000) * saltUsageInLbs * 0.453592)} kg`;
        }
        else if (saltMeasurementUnit === 'cups') {
            return `${Math.round((areaInSqFt / 1000) * saltUsageInLbs * 0.885)} cups`;
        }
    }
    if (events === 'pending') {
        return (React.createElement(LoadingCircle, { postalCode: postalCode }));
    }
    const timelineEvents = events.map((event, idx) => {
        if (event.type === 'salt') {
            if (!event.salt_usage_guidelines?.length) {
                throw new Error(`Missing salt guideline in event at time ${event.start_time_in_utc}`);
            }
            if (!event.salt_usage_guidelines[0].time_in_utc) {
                throw new Error(`Missing time_in_utc in salt guideline at time ${event.start_time_in_utc}`);
            }
            ;
            if (typeof event.salt_usage_guidelines[0].salt_usage_in_lbs === 'undefined') {
                throw new Error(`Missing or redundant salt usage guideline in salt guideline ` +
                    `at time ${event.start_time_in_utc}`);
            }
            ;
            if (event.salt_usage_guidelines[0].salt_usage_in_lbs === 0) {
                // excess amount of hours doesn't require salt according to sicops chart
                // https://legacy.horttrades.com/assets/1594473479.SICOPS_chart_feb_2020-web.png
                return null;
            }
            ;
            const formattedLocalTime = handleTimeFormat(event.salt_usage_guidelines[0].time_in_utc, 'MMM DD hh:mm A');
            return (React.createElement(TimelineEvent, { color: "blue", key: idx, icon: GiSaltShaker },
                React.createElement(Title, null,
                    "Salt ",
                    convertSaltMeasurementUnit(areaInSqFt, saltMeasurementUnit, event.salt_usage_guidelines[0].salt_usage_in_lbs)),
                React.createElement(Subtitle, null,
                    "\u23F0 ",
                    formattedLocalTime)));
        }
        else {
            const formattedLocalTime = handleTimeFormat(event.start_time_in_utc, 'MMM DD hh:mm A');
            if (typeof event.snow_depth_in_cm === 'undefined') {
                throw new Error(`Missing snow depth in event at time ${event.start_time_in_utc}`);
            }
            ;
            let formattedSnowDepth;
            if (lengthUnit === 'in') {
                const roundedValue = Math.round(event.snow_depth_in_cm * 0.393701 * 2) / 2;
                formattedSnowDepth = `${roundedValue % 1 === 0 ? Math.floor(roundedValue) : roundedValue}"`;
            }
            else {
                formattedSnowDepth = `${Math.round(event.snow_depth_in_cm)} cm`;
            }
            return (React.createElement(TimelineEvent, { color: "red", key: idx, icon: PiShovelFill },
                React.createElement(Title, null,
                    "Plow ",
                    formattedSnowDepth),
                React.createElement(Subtitle, null,
                    "\u23F0 ",
                    formattedLocalTime)));
        }
    });
    const filteredTimelineEvents = timelineEvents.filter((event) => event !== null);
    const guidelinesTitle = (dayjs(startTimeUTC).add(10, 'minute').isBefore(dayjs())) ?
        `Last guideline for ${postalCode.toUpperCase()} on ` :
        `Guidelines for ${postalCode.toUpperCase()} for next 48 hours`;
    const emptyGuidelinesMessage = (dayjs(startTimeUTC).add(10, 'minute').isBefore(dayjs())) ?
        `No salting guidelines found necessary for ${postalCode.toUpperCase()} in last 14 days` :
        `No salting found necessary for ${postalCode.toUpperCase()} in the next 48 hours.`;
    return (React.createElement(React.Fragment, null, isValidPostalCode && (filteredTimelineEvents.length > 0 ? (React.createElement(Timeline, { title: guidelinesTitle }, filteredTimelineEvents)) : (React.createElement("div", { style: {
            textAlign: 'center',
            fontSize: '18px',
            color: '#D32F2F',
            fontWeight: '500',
            marginTop: '20px',
            padding: '10px',
            borderRadius: '5px',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            backgroundColor: '#FFF9F9'
        } }, emptyGuidelinesMessage)))));
};
function SaltUsageOptions() {
    const authContext = useAuthContext();
    const [events, setEvents] = useState([]);
    const [postalCode, setPostalCode] = useState('');
    const [postalCodeCoords, setPostalCodeCoords] = useState(null);
    const [isValidPostalCode, setIsValidPostalCode] = useState(false);
    const [isOutOfRangePostalCode, setIsOutOfRangePostalCode] = useState(false);
    const [startTimeUTC, setStartTimeUTC] = useState(dayjs().utc().toISOString());
    const [endTimeUTC, setEndTimeUTC] = useState(dayjs().utc().add(2, 'day').toISOString());
    const [lengthUnit, setLengthUnit] = useState('cm');
    const [visibleModal, setVisibleModal] = useState(null);
    const [carCount, setCarCount] = useState(2);
    const [houseOrientation, setHouseOrientation] = useState('side');
    const [saltMeasurementUnit, setSaltMeasurementUnit] = useState('lbs');
    const { isMobile, isTablet } = DeviceDetect();
    const isDesktop = !isMobile && !isTablet;
    useEffect(() => {
        const eventSegments = document.querySelectorAll('.min-w-40');
        const eventSegmentRatio = Math.floor(100 / eventSegments.length);
        eventSegments.forEach(eventSegment => {
            eventSegment.style.maxWidth = `${eventSegmentRatio}vw`;
        });
        const titles = document.querySelectorAll('.text-xl');
        titles.forEach(title => {
            if (isDesktop) {
                title.style.fontSize = '1.25rem';
            }
            else {
                title.style.fontSize = '1rem';
            }
        });
        const subtitles = document.querySelectorAll('.text-xs');
        subtitles.forEach(subtitle => {
            if (isDesktop) {
                subtitle.style.fontSize = '1rem';
            }
            else {
                subtitle.style.fontSize = '0.75rem';
            }
        });
        const icons = document.querySelectorAll('.absolute');
        icons.forEach(icon => {
            icon.style.top = 'calc(23% - clamp(20px, 1vw, 40px))';
            icon.style.height = '40px';
            icon.style.width = '40px';
        });
    }, [events, isDesktop]);
    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        if (!authContext.currentUser || !authContext.idToken) {
            return;
        }
        if (!postalCodeCoords)
            return;
        const coordsStr = `${postalCodeCoords[0].toFixed(2)}_${postalCodeCoords[1].toFixed(2)}`;
        const weatherEventsRequest = {
            startTime: startTimeUTC,
            endTime: endTimeUTC,
            locations: [{ lat: postalCodeCoords[0], lng: postalCodeCoords[1] }],
            boundFactor: METEO_DB_5500m_BOUND_FACTOR,
            snowDepthTrigger: MIN_SNOW_DEPTH_FOR_SALT_USAGE,
        };
        async function fetchEvents() {
            try {
                setEvents('pending');
                const response = await axios.post(`${baseURL}/salt-usage-estimate`, {
                    postalCode,
                    coordsStr,
                    weatherEventsRequest,
                    uid: authContext.currentUser.uid,
                    idToken: authContext.idToken,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (response.status === 404) {
                    setEvents([]);
                }
                else if (response.status === 200) {
                    const isLastEventsRequest = dayjs(weatherEventsRequest.startTime)
                        .subtract(1, 'day')
                        .isBefore(dayjs().utc());
                    if (isLastEventsRequest) {
                        const filteredEvents = response.data.filter((event) => (event.type === 'plow' ||
                            (typeof event.salt_usage_guidelines !== 'undefined' &&
                                event.salt_usage_guidelines.length > 0 &&
                                event.salt_usage_guidelines[0].salt_usage_in_lbs > LAST_EVENTS_MINIMUM_SALT_USAGE_IN_LBS)));
                        setEvents(filteredEvents);
                    }
                    else {
                        setEvents(response.data);
                    }
                }
                else {
                    console.error(`Failed to fetch events for postal code ${postalCode}`);
                }
            }
            catch (e) {
                console.error(`Failed to fetch events for postal code ${postalCode}: ${e}`);
            }
        }
        ;
        await fetchEvents();
    }, [authContext.currentUser, authContext.idToken, postalCode, postalCodeCoords, startTimeUTC, endTimeUTC]);
    useEffect(() => {
        const isCanadianPostalCode = /^[A-Za-z]\d[A-Za-z]$/;
        const isUSPostalCode = /^[0-9]{5}$/;
        if (!isCanadianPostalCode.test(postalCode) && !isUSPostalCode.test(postalCode)) {
            return;
        }
        ;
        if (isUSPostalCode.test(postalCode)) {
            setLengthUnit('in');
        }
        else {
            setLengthUnit('cm');
        }
        ;
        async function resolveCoords() {
            try {
                let coords = await fetchPostalCodeCoords(postalCode);
                if (!coords) {
                    console.error(`Postal code is valid input but ${postalCode} was not found!`);
                    return;
                }
                setIsValidPostalCode(true);
                setPostalCodeCoords(coords);
                if (!validateLocation([coords[0], coords[1]])) {
                    setIsOutOfRangePostalCode(true);
                }
            }
            catch (e) {
                console.error(`Error fetching coords for ${postalCode}!`);
                setIsValidPostalCode(false);
            }
        }
        resolveCoords();
    }, [postalCode, isValidPostalCode]);
    useEffect(() => {
        if (isValidPostalCode && !isOutOfRangePostalCode) {
            handleSubmit(new Event('submit'));
        }
    }, [isValidPostalCode, isOutOfRangePostalCode, startTimeUTC, handleSubmit]);
    function handlePostalCodeChange(postalCode, startTimeUTC, endTimeUTC) {
        setIsValidPostalCode(false);
        setIsOutOfRangePostalCode(false);
        setPostalCodeCoords(null);
        const isCanadianPostalCode = /^[A-Za-z]\d[A-Za-z]$/;
        if (isCanadianPostalCode.test(postalCode)) {
            const formattedPostalCode = (postalCode).toUpperCase();
            setPostalCode(formattedPostalCode);
        }
        else {
            setPostalCode(postalCode);
        }
        setStartTimeUTC(startTimeUTC);
        setEndTimeUTC(endTimeUTC);
    }
    ;
    function computeAreaInSqFt(carCount, houseOrientation) {
        const drivewaySize = (11 * carCount) * 32; // 11 ft wide/car * 32 ft length (2 honda accords)
        const sideWalkLength = 25 + (11 * carCount);
        const additionalCornerLength = 25;
        const sidewalkArea = 5 * (sideWalkLength + (houseOrientation === 'corner' ?
            additionalCornerLength :
            0)); // assume sidewalks are 5 ft wide (1.5m)
        return drivewaySize + sidewalkArea;
    }
    ;
    // Note: this doesn't handle errors in user input for postal codes that dne according to Google
    const handleInvalidPostalCode = () => postalCode.length > 0 && !isValidPostalCode;
    return (React.createElement("div", { style: {
            maxWidth: '600px',
            margin: '0 auto',
            padding: '1rem',
        } },
        React.createElement("h1", { style: { textAlign: 'center' } }, "Salt Usage Guidelines"),
        React.createElement("form", { onSubmit: handleSubmit, style: {
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                marginBottom: '1rem',
            } },
            React.createElement(Typography, { variant: 'body1' }, "Enter US zip code or first three characters of your Canadian postal code:"),
            React.createElement(TextField, { id: "postalCode", type: "text", variant: "outlined", placeholder: "e.g. M3A or 14204", value: postalCode, onChange: (e) => {
                    handlePostalCodeChange(e.target.value, dayjs().utc().toISOString(), dayjs().utc().add(2, 'day').toISOString());
                }, error: handleInvalidPostalCode(), helperText: handleInvalidPostalCode() ?
                    "Please enter a valid postal code, like M3A or 14204" :
                    (isOutOfRangePostalCode ?
                        `${postalCode} is out of range` :
                        ""), sx: {
                    '& .MuiInputBase-input': {
                        fontSize: '1rem',
                    },
                    '& .MuiOutlinedInput-root': {
                        '&.Mui-error fieldset': {
                            borderColor: 'red',
                        },
                    },
                }, fullWidth: true, InputProps: {
                    endAdornment: (React.createElement(InputAdornment, { position: "end" },
                        React.createElement(IconButton, { disabled: handleInvalidPostalCode(), onClick: () => {
                                const LOOKBEHIND_IN_DAYS = 7 * 2;
                                const lookBehindDate = dayjs().utc()
                                    .subtract(LOOKBEHIND_IN_DAYS, 'day').toISOString();
                                setStartTimeUTC(lookBehindDate);
                                setEndTimeUTC(dayjs().utc().toISOString());
                            }, edge: "end", "aria-label": "fetch history", sx: { paddingRight: '0.25rem' } },
                            React.createElement(MdHistory, { size: 40 })))),
                } })),
        React.createElement("div", null,
            React.createElement("h3", { style: { textAlign: 'center' } }, "Demo presets (internal use)"),
            React.createElement("div", { style: { display: 'flex', justifyContent: 'space-around', gap: '5px' } },
                React.createElement("button", { onClick: (e) => {
                        handlePostalCodeChange('L6W', '2025-01-01T13:00:00Z', '2025-01-02T13:00:00Z');
                    }, style: {
                        padding: '0.8rem',
                        fontSize: '1rem',
                        cursor: 'pointer',
                        backgroundColor: '#007BFF',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '4px',
                        transition: 'background-color 0.3s',
                    }, onMouseOver: (e) => e.currentTarget.style.backgroundColor = '#0056b3', onMouseOut: (e) => e.currentTarget.style.backgroundColor = '#007BFF' }, "L6W | 2025-01-02"),
                React.createElement("button", { onClick: (e) => {
                        handlePostalCodeChange('M3A', '2025-01-01T13:00:00Z', '2025-01-02T13:00:00Z');
                    }, style: {
                        padding: '0.8rem',
                        fontSize: '1rem',
                        cursor: 'pointer',
                        backgroundColor: '#007BFF',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '4px',
                        transition: 'background-color 0.3s',
                    }, onMouseOver: (e) => e.currentTarget.style.backgroundColor = '#0056b3', onMouseOut: (e) => e.currentTarget.style.backgroundColor = '#007BFF' }, "M3A | 2025-01-02"),
                React.createElement("button", { onClick: (e) => {
                        handlePostalCodeChange('N2H', '2025-01-01T13:00:00Z', '2025-01-02T13:00:00Z');
                    }, style: {
                        padding: '0.8rem',
                        fontSize: '1rem',
                        cursor: 'pointer',
                        backgroundColor: '#007BFF',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '4px',
                        transition: 'background-color 0.3s',
                    }, onMouseOver: (e) => e.currentTarget.style.backgroundColor = '#0056b3', onMouseOut: (e) => e.currentTarget.style.backgroundColor = '#007BFF' }, "N2H | 2025-01-02"))),
        React.createElement("hr", { style: {
                color: '#343a40',
                backgroundColor: '#343a40',
                height: '2px',
                border: 'none',
                margin: '2rem 0'
            } }),
        React.createElement("div", { style: {
                width: '100%',
                overflowX: 'auto',
            } },
            React.createElement(TimelineChart, { postalCode: postalCode, isValidPostalCode: isValidPostalCode, startTimeUTC: startTimeUTC, endTimeUTC: endTimeUTC, events: events, saltMeasurementUnit: saltMeasurementUnit, areaInSqFt: computeAreaInSqFt(carCount, houseOrientation), lengthUnit: lengthUnit })),
        events.length > 0 && (React.createElement("div", { style: {
                width: '100%',
                overflowX: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '2rem',
            } },
            React.createElement("div", { style: {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    gap: '2rem'
                } },
                React.createElement("div", { style: {
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column'
                    } },
                    React.createElement(FormControl, { fullWidth: true },
                        React.createElement(NativeSelect, { defaultValue: 2, value: carCount, onChange: (e) => setCarCount(parseInt(e.target.value)), inputProps: {
                                name: 'carCount',
                                id: 'uncontrolled-native',
                                'aria-label': 'Select number of cars in driveway'
                            }, sx: {
                                height: '4rem',
                                fontSize: '1.5rem',
                                '& .MuiSelect-select': {
                                    textAlign: 'center'
                                }
                            } },
                            React.createElement("option", { value: 1, style: { textAlign: 'center' } }, "\uD83D\uDE98"),
                            React.createElement("option", { value: 2, style: { textAlign: 'center' } }, "\uD83D\uDE98 \uD83D\uDE98"),
                            React.createElement("option", { value: 3, style: { textAlign: 'center' } }, "\uD83D\uDE98 \uD83D\uDE98 \uD83D\uDE98"))),
                    React.createElement(Typography, { variant: "h6", sx: {
                            display: 'flex', alignItems: 'center',
                            mt: 1, textAlign: 'center', gap: '0.25rem',
                            fontSize: '1.125rem'
                        } },
                        "Driveway width",
                        React.createElement(Tooltip, { title: 'driveway width', onClick: () => setVisibleModal('carCount') },
                            React.createElement(InfoDetailsIcon, null)))),
                React.createElement("div", { style: {
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column'
                    } },
                    React.createElement(ToggleButtonGroup, { value: houseOrientation, onChange: (e, newOrientation) => {
                            if (newOrientation !== null) {
                                setHouseOrientation(newOrientation);
                            }
                        }, exclusive: true, "aria-label": "House orientation", sx: {
                            height: '4rem',
                            '& .MuiToggleButton-root': {
                                flex: 1
                            }
                        } },
                        React.createElement(ToggleButton, { value: "corner", "aria-label": "Corner house" }, "\uD83C\uDFE0 Corner"),
                        React.createElement(ToggleButton, { value: "side", "aria-label": "Side house" }, "\uD83C\uDFE0 Side")),
                    React.createElement(Typography, { variant: "h6", sx: {
                            display: 'flex', alignItems: 'center',
                            mt: 1, textAlign: 'center', gap: '0.25rem',
                            fontSize: '1.125rem'
                        } },
                        "Corner house?",
                        React.createElement(Tooltip, { title: 'house orientation', onClick: () => setVisibleModal('houseOrientation') },
                            React.createElement(InfoDetailsIcon, null))))),
            React.createElement("div", { style: { width: '100%', marginTop: '1rem', display: 'flex', flexDirection: 'column', gap: '1rem' } },
                React.createElement(ToggleButtonGroup, { value: saltMeasurementUnit, onChange: (e, newUnit) => {
                        if (newUnit !== null) {
                            setSaltMeasurementUnit(newUnit);
                        }
                    }, exclusive: true, "aria-label": "Salt measurement unit", sx: {
                        width: '100%',
                        '& .MuiToggleButton-root': {
                            flex: 1,
                            textTransform: 'none',
                        }
                    } },
                    React.createElement(ToggleButton, { value: "cups", "aria-label": "Cups" }, "cups"),
                    React.createElement(ToggleButton, { defaultChecked: true, value: "lbs", "aria-label": "lbs" }, "lbs."),
                    React.createElement(ToggleButton, { value: "kg", "aria-label": "kg" }, "kg")),
                React.createElement(Typography, { variant: "h6", sx: {
                        display: 'flex', alignItems: 'center',
                        textAlign: 'center', gap: '0.25rem',
                        fontSize: '1.125rem'
                    } },
                    "How do you measure salt?",
                    React.createElement(Tooltip, { title: 'salt measurement unit', onClick: () => setVisibleModal('saltMeasurementUnit') },
                        React.createElement(InfoDetailsIcon, null)))))),
        React.createElement("hr", { style: {
                color: '#343a40',
                backgroundColor: '#343a40',
                height: '2px',
                border: 'none',
                margin: '2rem 0'
            } }),
        React.createElement(DisclaimerModal, null),
        React.createElement(InfoDetailsModal, { visibleModal: visibleModal, handleClose: () => setVisibleModal(null) })));
}
const MemoizedSaltUsageOptions = memo(SaltUsageOptions);
export default MemoizedSaltUsageOptions;
