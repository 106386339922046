export const PRECIPITATION_TYPE = {
    'NONE': 0,
    'RAIN': 1,
    'RAIN_AND_SNOW_MIXED': 2,
    'SNOW': 3,
    'SLEET': 4,
    'FREEZING_RAIN': 5,
    'HAIL': 6,
};
export const METEO_DB_1100m_PRECISION_IN_DECIMALS = 2;
export const METEO_DB_1100m_BOUND_FACTOR = (1 / 10 ** METEO_DB_1100m_PRECISION_IN_DECIMALS);
export const METEO_DB_5500m_BOUND_FACTOR = 0.05;
// export const DEFAULT_AREA_TO_PLOW_IN_SQUARE_METERS = 0;
// export const DEFAULT_TIME_REQUIRED_TO_PLOW_IN_SECONDS = 0;
export const MIN_SNOW_DEPTH_FOR_SALT_USAGE = 0.5;
export const MAX_SNOW_DEPTH_WITHOUT_PLOW_IN_CM = 2.5;
export const MAX_SNOW_DEPTH_TO_CONSOLIDATE_IN_CM = 10;
export const PAVEMENT_TEMP_UPPER_BOUND_IN_C = -1;
export const PAVEMENT_TEMP_LOWER_BOUND_IN_C = -7;
export const PAVEMENT_TEMP_LOWEST_BOUND_IN_C = -20;
export const BSU = 5; // BASELINE_SALT_USAGE_IN_LBS
export const MAX_TIME_BETWEEN_SALT_EVENTS_FOR_MERGE_IN_MINS = 4 * 60;
export const saltChart = [
    // -------------------------------------------------------
    // 0.5 cm (original data)
    // -------------------------------------------------------
    { snowDepth: 0.5, temp: -7, saltAmounts: [85, 70, 55, 45, 30, 15, BSU, BSU] },
    { snowDepth: 0.5, temp: -5, saltAmounts: [75, 60, 45, 30, 15, BSU, BSU, BSU] },
    { snowDepth: 0.5, temp: -3, saltAmounts: [60, 45, 30, 15, BSU, BSU, BSU, BSU] },
    { snowDepth: 0.5, temp: -1, saltAmounts: [45, 30, 15, BSU, BSU, BSU, BSU, BSU] },
    { snowDepth: 0.5, temp: 0, saltAmounts: [40, 25, 10, BSU, BSU, BSU, BSU, BSU] },
    // -------------------------------------------------------
    // 1.0 cm (original data)
    // -------------------------------------------------------
    { snowDepth: 1.0, temp: -7, saltAmounts: [90, 75, 60, 45, 30, 20, 5, BSU] },
    { snowDepth: 1.0, temp: -5, saltAmounts: [75, 60, 50, 35, 20, 5, BSU, BSU] },
    { snowDepth: 1.0, temp: -3, saltAmounts: [65, 50, 35, 20, 5, BSU, BSU, BSU] },
    { snowDepth: 1.0, temp: -1, saltAmounts: [50, 35, 20, 5, BSU, BSU, BSU, BSU] },
    { snowDepth: 1.0, temp: 0, saltAmounts: [40, 30, 15, BSU, BSU, BSU, BSU, BSU] },
    // -------------------------------------------------------
    // 1.5 cm (original data)
    // -------------------------------------------------------
    { snowDepth: 1.5, temp: -7, saltAmounts: [95, 80, 65, 50, 35, 20, 10, BSU] },
    { snowDepth: 1.5, temp: -5, saltAmounts: [80, 65, 50, 35, 25, 10, BSU, BSU] },
    { snowDepth: 1.5, temp: -3, saltAmounts: [65, 55, 40, 25, 10, BSU, BSU, BSU] },
    { snowDepth: 1.5, temp: -1, saltAmounts: [55, 40, 25, 10, BSU, BSU, BSU, BSU] },
    { snowDepth: 1.5, temp: 0, saltAmounts: [45, 30, 15, 5, BSU, BSU, BSU, BSU] },
    // -------------------------------------------------------
    // 2.0 cm (original data)
    // -------------------------------------------------------
    { snowDepth: 2.0, temp: -7, saltAmounts: [100, 85, 70, 55, 40, 25, 10, BSU] },
    { snowDepth: 2.0, temp: -5, saltAmounts: [85, 70, 55, 40, 25, 10, BSU, BSU] },
    { snowDepth: 2.0, temp: -3, saltAmounts: [70, 55, 40, 30, 15, BSU, BSU, BSU] },
    { snowDepth: 2.0, temp: -1, saltAmounts: [55, 45, 30, 15, BSU, BSU, BSU, BSU] },
    { snowDepth: 2.0, temp: 0, saltAmounts: [50, 35, 20, 5, BSU, BSU, BSU, BSU] },
    // -------------------------------------------------------
    // 2.5 cm (original data)
    // -------------------------------------------------------
    { snowDepth: 2.5, temp: -7, saltAmounts: [105, 90, 75, 60, 45, 30, 15, BSU] },
    { snowDepth: 2.5, temp: -5, saltAmounts: [90, 75, 60, 45, 30, 15, BSU, BSU] },
    { snowDepth: 2.5, temp: -3, saltAmounts: [75, 60, 45, 30, 15, 5, BSU, BSU] },
    { snowDepth: 2.5, temp: -1, saltAmounts: [60, 45, 35, 20, 5, BSU, BSU, BSU] },
    { snowDepth: 2.5, temp: 0, saltAmounts: [55, 40, 25, 10, BSU, BSU, BSU, BSU] },
    // =======================================================
    // Extrapolated values from 3.0 cm up to 10.0 cm
    // =======================================================
    // -------------------------------------------------------
    // 3.0 cm
    // -------------------------------------------------------
    { snowDepth: 3.0, temp: -7, saltAmounts: [110, 95, 80, 65, 50, 35, 20, BSU] },
    { snowDepth: 3.0, temp: -5, saltAmounts: [95, 80, 65, 50, 35, 20, BSU, BSU] },
    { snowDepth: 3.0, temp: -3, saltAmounts: [80, 65, 50, 30, 15, 10, BSU, BSU] },
    { snowDepth: 3.0, temp: -1, saltAmounts: [65, 45, 40, 25, 5, 5, 5, BSU] },
    { snowDepth: 3.0, temp: 0, saltAmounts: [60, 45, 30, 15, 5, 5, 5, BSU] },
    // -------------------------------------------------------
    // 3.5 cm
    // -------------------------------------------------------
    { snowDepth: 3.5, temp: -7, saltAmounts: [115, 100, 85, 70, 55, 40, 25, BSU] },
    { snowDepth: 3.5, temp: -5, saltAmounts: [100, 85, 70, 55, 40, 25, BSU, BSU] },
    { snowDepth: 3.5, temp: -3, saltAmounts: [85, 70, 55, 30, 15, 15, BSU, BSU] },
    { snowDepth: 3.5, temp: -1, saltAmounts: [70, 45, 45, 30, 5, 5, 5, BSU] },
    { snowDepth: 3.5, temp: 0, saltAmounts: [65, 50, 35, 20, 5, 5, 5, BSU] },
    // -------------------------------------------------------
    // 4.0 cm
    // -------------------------------------------------------
    { snowDepth: 4.0, temp: -7, saltAmounts: [120, 105, 90, 75, 60, 45, 30, BSU] },
    { snowDepth: 4.0, temp: -5, saltAmounts: [105, 90, 75, 60, 45, 30, BSU, BSU] },
    { snowDepth: 4.0, temp: -3, saltAmounts: [90, 75, 60, 30, 15, 20, BSU, BSU] },
    { snowDepth: 4.0, temp: -1, saltAmounts: [75, 45, 50, 35, 5, 5, 5, BSU] },
    { snowDepth: 4.0, temp: 0, saltAmounts: [70, 55, 40, 25, 5, 5, 5, BSU] },
    // -------------------------------------------------------
    // 4.5 cm
    // -------------------------------------------------------
    { snowDepth: 4.5, temp: -7, saltAmounts: [125, 110, 95, 80, 65, 50, 35, BSU] },
    { snowDepth: 4.5, temp: -5, saltAmounts: [110, 95, 80, 65, 50, 35, BSU, BSU] },
    { snowDepth: 4.5, temp: -3, saltAmounts: [95, 80, 65, 30, 15, 25, BSU, BSU] },
    { snowDepth: 4.5, temp: -1, saltAmounts: [80, 45, 55, 40, 5, 5, 5, BSU] },
    { snowDepth: 4.5, temp: 0, saltAmounts: [75, 60, 45, 30, 5, 5, 5, BSU] },
    // -------------------------------------------------------
    // 5.0 cm
    // -------------------------------------------------------
    { snowDepth: 5.0, temp: -7, saltAmounts: [130, 115, 100, 85, 70, 55, 40, BSU] },
    { snowDepth: 5.0, temp: -5, saltAmounts: [115, 100, 85, 70, 55, 40, BSU, BSU] },
    { snowDepth: 5.0, temp: -3, saltAmounts: [100, 85, 70, 30, 15, 30, BSU, BSU] },
    { snowDepth: 5.0, temp: -1, saltAmounts: [85, 45, 60, 45, 5, 5, 5, BSU] },
    { snowDepth: 5.0, temp: 0, saltAmounts: [80, 65, 50, 35, 5, 5, 5, BSU] },
    // -------------------------------------------------------
    // 5.5 cm
    // -------------------------------------------------------
    { snowDepth: 5.5, temp: -7, saltAmounts: [135, 120, 105, 90, 75, 60, 45, BSU] },
    { snowDepth: 5.5, temp: -5, saltAmounts: [120, 105, 90, 75, 60, 45, BSU, BSU] },
    { snowDepth: 5.5, temp: -3, saltAmounts: [105, 90, 75, 30, 15, 35, BSU, BSU] },
    { snowDepth: 5.5, temp: -1, saltAmounts: [90, 45, 65, 50, 5, 5, 5, BSU] },
    { snowDepth: 5.5, temp: 0, saltAmounts: [85, 70, 55, 40, 5, 5, 5, BSU] },
    // -------------------------------------------------------
    // 6.0 cm
    // -------------------------------------------------------
    { snowDepth: 6.0, temp: -7, saltAmounts: [140, 125, 110, 95, 80, 65, 50, BSU] },
    { snowDepth: 6.0, temp: -5, saltAmounts: [125, 110, 95, 80, 65, 50, BSU, BSU] },
    { snowDepth: 6.0, temp: -3, saltAmounts: [110, 95, 80, 30, 15, 40, BSU, BSU] },
    { snowDepth: 6.0, temp: -1, saltAmounts: [95, 45, 70, 55, 5, 5, 5, BSU] },
    { snowDepth: 6.0, temp: 0, saltAmounts: [90, 75, 60, 45, 5, 5, 5, BSU] },
    // -------------------------------------------------------
    // 6.5 cm
    // -------------------------------------------------------
    { snowDepth: 6.5, temp: -7, saltAmounts: [145, 130, 115, 100, 85, 70, 55, BSU] },
    { snowDepth: 6.5, temp: -5, saltAmounts: [130, 115, 100, 85, 70, 55, BSU, BSU] },
    { snowDepth: 6.5, temp: -3, saltAmounts: [115, 100, 85, 30, 15, 45, BSU, BSU] },
    { snowDepth: 6.5, temp: -1, saltAmounts: [100, 45, 75, 60, 5, 5, 5, BSU] },
    { snowDepth: 6.5, temp: 0, saltAmounts: [95, 80, 65, 50, 5, 5, 5, BSU] },
    // -------------------------------------------------------
    // 7.0 cm
    // -------------------------------------------------------
    { snowDepth: 7.0, temp: -7, saltAmounts: [150, 135, 120, 105, 90, 75, 60, BSU] },
    { snowDepth: 7.0, temp: -5, saltAmounts: [135, 120, 105, 90, 75, 60, BSU, BSU] },
    { snowDepth: 7.0, temp: -3, saltAmounts: [120, 105, 90, 30, 15, 50, BSU, BSU] },
    { snowDepth: 7.0, temp: -1, saltAmounts: [105, 45, 80, 65, 5, 5, 5, BSU] },
    { snowDepth: 7.0, temp: 0, saltAmounts: [100, 85, 70, 55, 5, 5, 5, BSU] },
    // -------------------------------------------------------
    // 7.5 cm
    // -------------------------------------------------------
    { snowDepth: 7.5, temp: -7, saltAmounts: [155, 140, 125, 110, 95, 80, 65, BSU] },
    { snowDepth: 7.5, temp: -5, saltAmounts: [140, 125, 110, 95, 80, 65, BSU, BSU] },
    { snowDepth: 7.5, temp: -3, saltAmounts: [125, 110, 95, 30, 15, 55, BSU, BSU] },
    { snowDepth: 7.5, temp: -1, saltAmounts: [110, 45, 85, 70, 5, 5, 5, BSU] },
    { snowDepth: 7.5, temp: 0, saltAmounts: [105, 90, 75, 60, 5, 5, 5, BSU] },
    // -------------------------------------------------------
    // 8.0 cm
    // -------------------------------------------------------
    { snowDepth: 8.0, temp: -7, saltAmounts: [160, 145, 130, 115, 100, 85, 70, BSU] },
    { snowDepth: 8.0, temp: -5, saltAmounts: [145, 130, 115, 100, 85, 70, BSU, BSU] },
    { snowDepth: 8.0, temp: -3, saltAmounts: [130, 115, 100, 30, 15, 60, BSU, BSU] },
    { snowDepth: 8.0, temp: -1, saltAmounts: [115, 45, 90, 75, 5, 5, 5, BSU] },
    { snowDepth: 8.0, temp: 0, saltAmounts: [110, 95, 80, 65, 5, 5, 5, BSU] },
    // -------------------------------------------------------
    // 8.5 cm
    // -------------------------------------------------------
    { snowDepth: 8.5, temp: -7, saltAmounts: [165, 150, 135, 120, 105, 90, 75, BSU] },
    { snowDepth: 8.5, temp: -5, saltAmounts: [150, 135, 120, 105, 90, 75, BSU, BSU] },
    { snowDepth: 8.5, temp: -3, saltAmounts: [135, 120, 105, 30, 15, 65, BSU, BSU] },
    { snowDepth: 8.5, temp: -1, saltAmounts: [120, 45, 95, 80, 5, 5, 5, BSU] },
    { snowDepth: 8.5, temp: 0, saltAmounts: [115, 100, 85, 70, 5, 5, 5, BSU] },
    // -------------------------------------------------------
    // 9.0 cm
    // -------------------------------------------------------
    { snowDepth: 9.0, temp: -7, saltAmounts: [170, 155, 140, 125, 110, 95, 80, BSU] },
    { snowDepth: 9.0, temp: -5, saltAmounts: [155, 140, 125, 110, 95, 80, BSU, BSU] },
    { snowDepth: 9.0, temp: -3, saltAmounts: [140, 125, 110, 30, 15, 70, BSU, BSU] },
    { snowDepth: 9.0, temp: -1, saltAmounts: [125, 45, 100, 85, 5, 5, 5, BSU] },
    { snowDepth: 9.0, temp: 0, saltAmounts: [120, 105, 90, 75, 5, 5, 5, BSU] },
    // -------------------------------------------------------
    // 9.5 cm
    // -------------------------------------------------------
    { snowDepth: 9.5, temp: -7, saltAmounts: [175, 160, 145, 130, 115, 100, 85, BSU] },
    { snowDepth: 9.5, temp: -5, saltAmounts: [160, 145, 130, 115, 100, 85, BSU, BSU] },
    { snowDepth: 9.5, temp: -3, saltAmounts: [145, 130, 115, 30, 15, 75, BSU, BSU] },
    { snowDepth: 9.5, temp: -1, saltAmounts: [130, 45, 105, 90, 5, 5, 5, BSU] },
    { snowDepth: 9.5, temp: 0, saltAmounts: [125, 110, 95, 80, 5, 5, 5, BSU] },
    // -------------------------------------------------------
    // 10.0 cm
    // -------------------------------------------------------
    { snowDepth: 10.0, temp: -7, saltAmounts: [180, 165, 150, 135, 120, 105, 90, BSU] },
    { snowDepth: 10.0, temp: -5, saltAmounts: [165, 150, 135, 120, 105, 90, BSU, BSU] },
    { snowDepth: 10.0, temp: -3, saltAmounts: [150, 135, 120, 30, 15, 80, BSU, BSU] },
    { snowDepth: 10.0, temp: -1, saltAmounts: [135, 45, 110, 95, 5, 5, 5, BSU] },
    { snowDepth: 10.0, temp: 0, saltAmounts: [130, 115, 100, 85, 5, 5, 5, BSU] },
];
