import axios from 'axios';
const postalCodeCoordsForL = {
    "L1A": [43.9629149, -78.2988637],
    "L1B": [43.9083566, -78.5944718],
    "L1C": [43.9080434, -78.68525509999999],
    "L1E": [43.8940611, -78.78162189999999],
    "L1G": [43.9260211, -78.8665733],
    "L1H": [43.8849512, -78.83260159999999],
    "L1J": [43.8652605, -78.8722341],
    "L1K": [43.9332185, -78.84392679999999],
    "L1L": [43.9531248, -78.8906295],
    "L1M": [43.952634, -78.9457948],
    "L1N": [43.8697004, -78.9175088],
    "L1P": [43.914065, -78.9853813],
    "L1R": [43.9232492, -78.9344814],
    "L1S": [43.8429373, -79.0306032],
    "L1T": [43.8776305, -79.04755589999999],
    "L1V": [43.8274868, -79.1153367],
    "L1W": [43.82422649999999, -79.0814523],
    "L1X": [43.8521146, -79.1012197],
    "L1Y": [43.9679684, -79.10404319999999],
    "L1Z": [43.8732498, -79.002342],
    "L2A": [42.9038743, -78.9344814],
    "L2E": [43.110234, -79.0814523],
    "L2G": [43.0729536, -79.09274839999999],
    "L2H": [43.0953927, -79.12662879999999],
    "L2J": [43.1315785, -79.10404319999999],
    "L2M": [43.1998378, -79.2169174],
    "L2N": [43.1934736, -79.2507534],
    "L2P": [43.1508732, -79.20563609999999],
    "L2R": [43.1455733, -79.30148450000002],
    "L2S": [43.13705390000001, -79.2620294],
    "L2T": [43.1338476, -79.2281975],
    "L3B": [42.9812998, -79.23947609999999],
    "L3C": [42.9941159, -79.273304],
    "L3K": [42.896821, -79.2620294],
    "L3M": [43.1931166, -79.5547244],
    "L3P": [43.8792741, -79.2620294],
    "L3R": [43.85519550000001, -79.3071196],
    "L3S": [43.8509327, -79.2620294],
    "L3T": [43.8205895, -79.391605],
    "L3V": [44.60770249999999, -79.4294664],
    "L3X": [44.0428916, -79.49850909999999],
    "L3Y": [44.0533612, -79.4591378],
    "L3Z": [44.114995, -79.56596329999999],
    "L4A": [43.9741113, -79.24229559999999],
    "L4B": [43.8584081, -79.391605],
    "L4C": [43.8815107, -79.4366324],
    "L4E": [43.9381619, -79.4366324],
    "L4G": [43.9947471, -79.4366324],
    "L4J": [43.8079675, -79.4591378],
    "L4K": [43.8121409, -79.5041322],
    "L4L": [43.799435, -79.5715822],
    "L4M": [44.4117642, -79.6838874],
    "L4N": [44.3680579, -79.7175517],
    "L4P": [44.2298189, -79.4619506],
    "L4R": [44.7515479, -79.87794989999999],
    "L4S": [43.902498, -79.40849279999999],
    "L4T": [43.7161148, -79.64459649999999],
    "L4V": [43.6950923, -79.62213679999999],
    "L4W": [43.6476344, -79.62213679999999],
    "L4X": [43.6150187, -79.5772008],
    "L4Y": [43.6075782, -79.5996716],
    "L4Z": [43.6222137, -79.6558242],
    "L5A": [43.5895967, -79.6109049],
    "L5B": [43.58214299999999, -79.6333674],
    "L5C": [43.5641466, -79.64459649999999],
    "L5E": [43.5854729, -79.56596329999999],
    "L5G": [43.5674879, -79.5772008],
    "L5H": [43.5420383, -79.6109049],
    "L5J": [43.5060127, -79.6333674],
    "L5K": [43.5254923, -79.66424409999999],
    "L5L": [43.5439375, -79.6838874],
    "L5M": [43.5617742, -79.72316119999999],
    "L5N": [43.5880906, -79.7512033],
    "L5P": [43.68351879999999, -79.612309],
    "L5R": [43.6147554, -79.6782755],
    "L5S": [43.6897036, -79.6670506],
    "L5T": [43.6517334, -79.6670506],
    "L5V": [43.5967648, -79.689499],
    "L5W": [43.63681589999999, -79.7119419],
    "L6A": [43.8688806, -79.5041322],
    "L6B": [43.8687116, -79.2253776],
    "L6C": [43.8783343, -79.352188],
    "L6E": [43.899227, -79.273304],
    "L6G": [43.8457704, -79.33247329999999],
    "L6H": [43.4888384, -79.70633169999999],
    "L6J": [43.4794825, -79.6558242],
    "L6K": [43.4348777, -79.689499],
    "L6L": [43.3954965, -79.7287703],
    "L6M": [43.4377177, -79.77363059999999],
    "L6P": [43.7928068, -79.70633169999999],
    "L6R": [43.7557544, -79.7680243],
    "L6S": [43.737989, -79.7287703],
    "L6T": [43.7149522, -79.6838874],
    "L6V": [43.7083413, -79.7680243],
    "L6W": [43.6810716, -79.7287703],
    "L6X": [43.6639094, -79.8016569],
    "L6Y": [43.647141, -79.77363059999999],
    "L6Z": [43.7293315, -79.7904475],
    "L7A": [43.691162, -79.84087869999999],
    "L7B": [43.9328727, -79.5322424],
    "L7C": [43.7523189, -79.8352767],
    "L7E": [43.87577450000001, -79.7343791],
    "L7G": [43.64442469999999, -79.9024779],
    "L7J": [43.63465420000001, -80.0339312],
    "L7K": [43.8635666, -79.9947956],
    "L7L": [43.3804487, -79.77363059999999],
    "L7M": [43.3791997, -79.8128649],
    "L7N": [43.3475161, -79.77923659999999],
    "L7P": [43.32586999999999, -79.85768279999999],
    "L7R": [43.3399741, -79.8016569],
    "L7S": [43.32399830000001, -79.810063],
    "L8E": [43.2192947, -79.6838874],
    "L8G": [43.2211048, -79.7568107],
    "L8H": [43.2528662, -79.7904475],
    "L8J": [43.1850304, -79.7287703],
    "L8K": [43.22514, -79.8016569],
    "L8L": [43.2578885, -79.84648039999999],
    "L8M": [43.2451669, -79.8380778],
    "L8N": [43.252457, -79.8660836],
    "L8P": [43.2523077, -79.8912813],
    "L8R": [43.2630311, -79.8772834],
    "L8S": [43.255291, -79.9248669],
    "L8T": [43.2175687, -79.8240715],
    "L8V": [43.2291551, -79.8688837],
    "L8W": [43.19511139999999, -79.84087869999999],
    "L9A": [43.2311551, -79.8688837],
    "L9B": [43.2044001, -79.8912813],
    "L9C": [43.2341458, -79.9024779],
    "L9G": [43.2229006, -79.99199949999999],
    "L9H": [43.2678392, -79.9584397],
    "L9J": [44.3244029, -79.70072110000001],
    "L9K": [43.225891, -79.94445259999999],
    "L9L": [44.1011091, -78.948623],
    "L9M": [44.7747226, -79.92780189999999],
    "L9N": [44.10397469999999, -79.49850909999999],
    "L9P": [44.1087666, -79.1238059],
    "L9R": [44.1544641, -79.87168369999999],
    "L9S": [44.3183135, -79.582819],
    "L9T": [43.5167511, -79.85768279999999],
    "L9V": [44.1696035, -80.23213129999999],
    "L9W": [43.9433318, -80.23213129999999],
    "L9Y": [44.4940717, -80.2990246],
    "L9Z": [44.4811789, -80.0423151]
};
const postalCodeCoordsForM = {
    "M3A": [43.7532586, -79.3296565],
    "M5A": [43.6542599, -79.36063589999999],
    "M6A": [43.718518, -79.4647633],
    "M7A": [43.6623015, -79.3894938],
    "M9A": [43.6678556, -79.5322424],
    "M1B": [43.8066863, -79.1943534],
    "M3B": [43.7459058, -79.352188],
    "M4B": [43.7063972, -79.30993699999999],
    "M5B": [43.6571618, -79.3789371],
    "M6B": [43.709577, -79.4450726],
    "M9B": [43.65094320000001, -79.5547244],
    "M1C": [43.78453510000001, -79.1604971],
    "M3C": [43.7258997, -79.34092299999999],
    "M4C": [43.6953439, -79.3183887],
    "M5C": [43.6514939, -79.3754179],
    "M6C": [43.6937813, -79.4281914],
    "M9C": [43.6435152, -79.5772008],
    "M1E": [43.7635726, -79.1887115],
    "M4E": [43.6763574, -79.2930312],
    "M5E": [43.6447708, -79.37330639999999],
    "M6E": [43.68902560000001, -79.453512],
    "M1G": [43.7709921, -79.2169174],
    "M4G": [43.7090604, -79.3634517],
    "M5G": [43.6579524, -79.3873826],
    "M6G": [43.669542, -79.4225637],
    "M1H": [43.773136, -79.23947609999999],
    "M2H": [43.8037622, -79.3634517],
    "M3H": [43.7543283, -79.4422593],
    "M4H": [43.7053689, -79.3493719],
    "M5H": [43.65057119999999, -79.3845675],
    "M6H": [43.6690051, -79.4422593],
    "M1J": [43.7447342, -79.23947609999999],
    "M2J": [43.7785175, -79.3465557],
    "M3J": [43.7679803, -79.4872619],
    "M4J": [43.685347, -79.3381065],
    "M5J": [43.6408157, -79.3817523],
    "M6J": [43.6479267, -79.4197497],
    "M1K": [43.7279292, -79.2620294],
    "M2K": [43.7869473, -79.385975],
    "M3K": [43.7374732, -79.4647633],
    "M4K": [43.6795571, -79.352188],
    "M5K": [43.6471768, -79.3815764],
    "M6K": [43.6368472, -79.4281914],
    "M1L": [43.7111117, -79.2845772],
    "M2L": [43.7574902, -79.37471409999999],
    "M3L": [43.7390146, -79.5069436],
    "M4L": [43.6689985, -79.3155716],
    "M5L": [43.6481985, -79.3798169],
    "M6L": [43.7137562, -79.49007379999999],
    "M9L": [43.7563033, -79.56596329999999],
    "M1M": [43.716316, -79.23947609999999],
    "M2M": [43.789053, -79.40849279999999],
    "M3M": [43.7284964, -79.4956974],
    "M4M": [43.6595255, -79.34092299999999],
    "M5M": [43.7332825, -79.4197497],
    "M6M": [43.6911158, -79.47601329999999],
    "M9M": [43.7247659, -79.5322424],
    "M1N": [43.692657, -79.2648481],
    "M2N": [43.7701199, -79.40849279999999],
    "M3N": [43.7616313, -79.5209994],
    "M4N": [43.7280205, -79.3887901],
    "M5N": [43.7116948, -79.4169356],
    "M6N": [43.6731853, -79.4872619],
    "M9N": [43.706876, -79.5181884],
    "M1P": [43.7574096, -79.273304],
    "M2P": [43.7527583, -79.40004929999999],
    "M4P": [43.7127511, -79.3901975],
    "M5P": [43.6969476, -79.4113072],
    "M6P": [43.6616083, -79.4647633],
    "M9P": [43.696319, -79.5322424],
    "M1R": [43.7500715, -79.2958491],
    "M2R": [43.7827364, -79.4422593],
    "M4R": [43.7153834, -79.4056784],
    "M5R": [43.6727097, -79.4056784],
    "M6R": [43.6489597, -79.45632499999999],
    "M7R": [43.6369656, -79.615819],
    "M9R": [43.6889054, -79.5547244],
    "M1S": [43.7942003, -79.2620294],
    "M4S": [43.7043244, -79.3887901],
    "M5S": [43.6626956, -79.40004929999999],
    "M6S": [43.6515706, -79.4844499],
    "M1T": [43.7816375, -79.3043021],
    "M4T": [43.6895743, -79.3831599],
    "M5T": [43.6532057, -79.40004929999999],
    "M1V": [43.8152522, -79.2845772],
    "M4V": [43.6864123, -79.40004929999999],
    "M5V": [43.6289467, -79.3944199],
    "M8V": [43.6056466, -79.5013207],
    "M9V": [43.7394164, -79.58843689999999],
    "M1W": [43.7995252, -79.3183887],
    "M4W": [43.6795626, -79.3775294],
    "M5W": [43.6464352, -79.37484599999999],
    "M8W": [43.6024137, -79.5434841],
    "M9W": [43.7067483, -79.59405439999999],
    "M1X": [43.8361247, -79.20563609999999],
    "M4X": [43.667967, -79.3676753],
    "M5X": [43.6484292, -79.3822802],
    "M7Y": [43.6627439, -79.321558],
    "M8Y": [43.6362579, -79.49850909999999],
    "M8Z": [43.6288408, -79.5209994]
};
const postalCodeCoordsForN = {
    "N1A": [42.9078435, -79.61371299999999],
    "N2A": [43.4421581, -80.4270885],
    "N3A": [43.3760936, -80.6964905],
    "N4A": [43.72588229999999, -79.3155716],
    "N5A": [43.3675005, -80.9760556],
    "N6A": [42.9975524, -81.2546366],
    "N7A": [43.77333520000001, -81.6692532],
    "N8A": [42.5982382, -82.4156829],
    "N9A": [42.3148498, -83.030767],
    "N1B": [45.6336957, -73.51503989999999],
    "N2B": [43.4631268, -80.44934049999999],
    "N3B": [43.5963661, -80.5605111],
    "N4B": [42.8494629, -80.491047],
    "N6B": [42.9818077, -81.2381151],
    "N9B": [42.2965441, -83.05224679999999],
    "N1C": [43.491899, -80.22655449999999],
    "N2C": [43.4153698, -80.44934049999999],
    "N3C": [43.4222258, -80.30459669999999],
    "N5C": [43.0360636, -80.8848199],
    "N6C": [42.9598744, -81.2353612],
    "N9C": [42.2782265, -83.0737195],
    "N1E": [43.5690978, -80.2377078],
    "N2E": [43.4104696, -80.5049445],
    "N3E": [43.4176705, -80.3352364],
    "N6E": [42.920388, -81.22434439999999],
    "N9E": [42.2658096, -83.030767],
    "N1G": [43.5171743, -80.2432839],
    "N2G": [43.44467239999999, -80.4854873],
    "N4G": [42.8669541, -80.7325287],
    "N6G": [43.0140325, -81.31243409999999],
    "N7G": [42.9501472, -81.6199484],
    "N9G": [42.2435578, -82.98778659999999],
    "N1H": [43.54339050000001, -80.2711588],
    "N2H": [43.456377, -80.4827074],
    "N3H": [43.3973533, -80.34915989999999],
    "N4H": [42.8669541, -80.7325287],
    "N5H": [42.770385, -80.9760556],
    "N6H": [42.9964098, -81.3344408],
    "N8H": [42.0467434, -82.5943072],
    "N9H": [42.2311275, -83.025396],
    "N2J": [43.4782577, -80.51606079999999],
    "N3J": [43.7679803, -79.4872619],
    "N5J": [43.6408157, -79.3817523],
    "N6J": [42.9535959, -81.27941229999999],
    "N9J": [42.214093, -83.0683519],
    "N1K": [43.52313940000001, -80.2850929],
    "N2K": [43.4945571, -80.4827074],
    "N4K": [44.5680371, -80.9401268],
    "N6K": [42.9542158, -81.3509416],
    "N9K": [42.2931658, -82.8775218],
    "N1L": [43.5149952, -80.1902963],
    "N2L": [43.47148749999999, -80.54940069999999],
    "N3L": [43.2069716, -80.3909164],
    "N4L": [44.5982269, -80.59383299999999],
    "N5L": [42.6647776, -81.2229672],
    "N6L": [42.8684828, -81.2408689],
    "N7L": [42.4177516, -82.19855179999999],
    "N1M": [43.71198039999999, -80.3797834],
    "N2M": [43.44006270000001, -80.51606079999999],
    "N4M": [43.6595255, -79.34092299999999],
    "N6M": [42.956601, -81.1306391],
    "N7M": [42.3876829, -82.1876776],
    "N8M": [42.1720084, -82.8236678],
    "N2N": [43.4228058, -80.5382889],
    "N4N": [44.14989749999999, -81.0202533],
    "N6N": [42.9003527, -81.1526976],
    "N8N": [42.2780514, -82.8317486],
    "N1P": [43.3335531, -80.30181069999999],
    "N2P": [43.3848224, -80.4270885],
    "N3P": [43.1857684, -80.22655449999999],
    "N5P": [42.7890007, -81.1830178],
    "N6P": [42.9033896, -81.3179364],
    "N8P": [42.3271946, -82.9125036],
    "N1R": [43.3744425, -80.30459669999999],
    "N2R": [43.3851754, -80.4882672],
    "N3R": [43.1747205, -80.2655846],
    "N4R": [43.7153834, -79.4056784],
    "N5R": [42.7665598, -81.1747498],
    "N8R": [42.3075917, -82.9125036],
    "N9R": [43.6889054, -79.5547244],
    "N1S": [43.3572147, -80.3268812],
    "N3S": [43.1483286, -80.2377078],
    "N4S": [43.1334081, -80.7491563],
    "N7S": [42.9873227, -82.3560389],
    "N8S": [42.32918770000001, -82.9447782],
    "N1T": [43.4021669, -80.29345219999999],
    "N2T": [43.4542343, -80.57161990000002],
    "N3T": [43.1564817, -80.1651854],
    "N4T": [43.153459, -80.72975710000001],
    "N7T": [42.9515312, -82.3994214],
    "N8T": [42.30958469999999, -82.9447782],
    "N2V": [43.4854945, -80.5743969],
    "N3V": [43.1754159, -80.30181069999999],
    "N4V": [43.1145494, -80.7242136],
    "N5V": [43.0162126, -81.1526976],
    "N7V": [42.9946912, -82.39399999999999],
    "N8V": [42.2687314, -82.95822129999999],
    "N9V": [42.103278, -83.1059154],
    "N3W": [43.0685131, -79.9556424],
    "N4W": [43.7351455, -80.9511835],
    "N5W": [42.9845838, -81.18026189999999],
    "N7W": [42.9702698, -82.318057],
    "N8W": [42.2821492, -82.97703709999999],
    "N9W": [43.7067483, -79.59405439999999],
    "N4X": [43.2580699, -81.1389118],
    "N5X": [43.046705, -81.2353612],
    "N7X": [43.0037409, -82.31262939999999],
    "N8X": [42.294585, -83.02002449999999],
    "N3Y": [42.8383305, -80.30181069999999],
    "N4Y": [43.6658599, -79.3831599],
    "N5Y": [43.0169302, -81.22434439999999],
    "N8Y": [42.3220287, -82.98778659999999],
    "N9Y": [42.0413019, -82.7401087],
    "N2Z": [44.172896, -81.6309078],
    "N4Z": [43.35402010000001, -81.0174917],
    "N5Z": [42.967833, -81.2133262]
};
const canadianPostalCodes = {
    ...postalCodeCoordsForL,
    ...postalCodeCoordsForM,
    ...postalCodeCoordsForN,
};
async function getCoordinates(postalCode, countryCode) {
    try {
        const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
            params: {
                address: `${postalCode}, ${countryCode}`,
                key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
            }
        });
        if (response.data.results && response.data.results.length) {
            const location = response.data.results[0].geometry.location;
            return [location.lat, location.lng];
        }
        return null;
    }
    catch (error) {
        console.error(`Error getting coordinates for ${postalCode}, ${countryCode}:`, error.message);
        return null;
    }
}
export async function fetchPostalCodeCoords(postalCode) {
    let candidateCoords = null;
    if (postalCode.length !== 3 && postalCode.length !== 5) {
        throw new Error(`Postal code ${postalCode} must be 3 or 5 characters long`);
    }
    ;
    if (postalCode.length === 5) {
        const isUSZipCode = /^[0-9]{5}$/;
        if (!isUSZipCode.test(postalCode)) {
            throw new Error(`Postal code ${postalCode} must be a valid US zip code`);
        }
        ;
        candidateCoords = await getCoordinates(postalCode, 'USA');
    }
    else {
        postalCode = postalCode.toUpperCase();
        const isCanadianPostalCode = /^[A-Za-z]\d[A-Za-z]$/;
        if (!isCanadianPostalCode.test(postalCode)) {
            throw new Error(`Postal code ${postalCode} must be a valid Canadian postal code`);
        }
        ;
        if (!(postalCode in canadianPostalCodes)) {
            candidateCoords = await getCoordinates(postalCode, 'Canada');
        }
        else {
            candidateCoords = canadianPostalCodes[postalCode];
        }
    }
    ;
    if (candidateCoords?.length !== 2) {
        throw new Error(`Invalid candidate coords for postal code ${postalCode}: ${candidateCoords}`);
    }
    return candidateCoords;
}
;
